import classNames from "classnames";
import SelectInput from "./SelectInput";
import PropTypes from "prop-types";

const Unit = ({ unit = "", value = "", onChangeUnit = () => {}, className = "" }) => {
  if (!!unit) {
    if (typeof unit === "string") {
      return (
        <span
          className={classNames("text-md px-3 text-gray-400", {
            [className]: !!className,
          })}
        >
          {unit}
        </span>
      );
    } else if (unit.length > 1) {
      const unitOptions = unit.map((un, idx) => ({ id: idx, value: un, label: un }));
      return (
        <SelectInput
          options={unitOptions}
          value={value}
          onChange={onChangeUnit}
          className={classNames("bg-gray-800 border-none ml-3 py-2", {
            [className]: !!className,
          })}
        />
      );
    }
  }
  return null;
};

Unit.propTypes = {
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChangeUnit: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default Unit;
